import "antd/dist/antd.css";
import "./styles.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route, Switch
} from "react-router-dom";
import { Download } from "./Download";
import { Manual } from "./Manual";

console.log(window.location.host)

export default function App() {
  return <Router>
    <Switch>
      <Route path="/numasoni" exact>
        <Download />
      </Route>
      <Route path="/numasoni/manual" exact>
        <Manual />
      </Route>
    </Switch>
  </Router>
}
