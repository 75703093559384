import "antd/dist/antd.css";
import React, { useState } from "react";
import styled from "styled-components";
import "./styles.css";
import { Menu } from 'antd'
import { MenuClickEventHandler } from "rc-menu/lib/interface";

const Container = styled.div`
  display: flex;
  height: 100vh;
`

const ManualMenu = styled(Menu)`
  width: 160px;
`

const ManualItem = styled(Menu.Item)`
  user-select: none;
`

const Content = styled.iframe`
  width: calc(100vw - 160px);
  border: none;
`

const manuals: Record<string, string> = {
  pc: 'https://numasoni-manual.s3-ap-northeast-1.amazonaws.com/8adb3a8a-1564-11eb-8b25-0cc47a792c0a_id_8adb3a8a-1564-11eb-8b25-0cc47a792c0a.html',
  vr: 'https://numasoni-manual.s3-ap-northeast-1.amazonaws.com/691da724-1565-11eb-8b25-0cc47a792c0a_id_691da724-1565-11eb-8b25-0cc47a792c0a.html',
}

export const Manual: React.FC = () => {
  const [key, setKey] = useState('pc')

  const menuClickHandler: MenuClickEventHandler = (event) => {
    setKey(event.key.toString())
  }

return (<Container>
    <ManualMenu selectedKeys={[key]} onClick={menuClickHandler}>
      <ManualItem key='pc'>PC 操作マニュアル</ManualItem>
      <ManualItem key='vr'>VR 操作マニュアル</ManualItem>
    </ManualMenu>
    <Content src={manuals[key]}>

    </Content>
  </Container>);
}
