import { Button, Checkbox } from "antd";
import "antd/dist/antd.css";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useState } from "react";
import styled from "styled-components";
import "./styles.css";
const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;
export const Download: React.FC = () => {
  const [agreement, setAgreement] = useState(false);
  const onCheckChange = function (event: CheckboxChangeEvent) {
    setAgreement(event.target.checked);
  };
  const downloadClick = function () {
    window.location.href =
      "https://virtualnhk.s3-ap-northeast-1.amazonaws.com/VirtualNHK-Setup.exe";
  };
  return (
    <Container>
      <Checkbox onChange={onCheckChange} checked={agreement}>
        <a href="https://www.nhk.or.jp/rules/terms/" target="_blank">
          NHKインターネットサービス利用規約
        </a>
        に同意する
      </Checkbox>
      <Button
        type="primary"
        size="large"
        disabled={!agreement}
        onClick={downloadClick}
        style={{
          marginTop: 20
        }}
      >
        アプリをダウンロード
      </Button>
    </Container>
  );
}
